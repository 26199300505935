.container{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    background-color: #1A1A1A;
    width: 100vw;
    height: 8vh;
    padding-left: 1vw;
    position: fixed;
    top:0;
    box-sizing: border-box;
    -moz-box-sizing: border-box; /* Firefox */
    -webkit-box-sizing: border-box; /* Safari, Chrome */
    z-index: 999;
}

.transparent{
    position: absolute;
    top: calc((100vh - 100vw * 969/1920)/2);
    background-color: #00000000;
}

.hamburgerIcon{
    position: inherit;
    display: flex;
    flex-direction: column;
    z-index: 10;
    top: 10px;
    right: 10px;
    cursor: pointer;
    padding-top: 1vw;
    padding-right: 2vw;
    transform-origin: 30% 60%;
    
}

.lettersHamburger{
    padding-top: 1vw;
    padding-right: 2vw;
    transform-origin: 30% 60%;
}

.logo{
    height: 48px;
    width: 100px;
    padding-top: 0.5vw;
}

.burgerBar{
    border-radius: 5px;
    border-top: 5px solid white;
    margin-bottom: 5px;
    width: 30px;
}

.rotate90{
    animation: rotate90 0.5s forwards;
}

@keyframes rotate90{
    from{
        transform: rotate(0deg);
    }
    to{
        transform: rotate(-90deg);
    }
}

.rotateReverse90{
    animation: rotateReverse90 0.5s forwards;
}

@keyframes rotateReverse90{
    from{
        transform: rotate(-90deg);
    }
    to{
        transform: rotate(0deg);
    }
}

.menu{
    position: fixed;
    width: 12vw;
    margin-top: 7vh;
    height: 38vh;
    left: 88vw;
    list-style-type: none;
    z-index: 10;
    pointer-events: none;
    background-color: #1A1A1A;
    opacity: 0;
}

.makeOpaque{
    pointer-events: all;
    animation: makeOpaque 1s forwards;
}

@keyframes makeOpaque{
    from{
        opacity: 0;
    }
    to{
        opacity: 1;
    }
}

.makeTransparent{
    pointer-events: none;
    animation: makeTransparent 1s forwards;
}

@keyframes makeTransparent{
    from{
        opacity: 1;
    }
    to{
        opacity: 0;
    }
}

.menuItem{
    color: #c4c5c7;
    text-align: right;
    /* margin-left: 15vw; */
    width: 10vw;
    font-size: 1.5vw;
    padding: 1vh 0 1vh 0;
    cursor: pointer;
}

.menuItem:hover{
    color: #ffffff;
}

.menuInHome{
    margin-left: 15vw;
    animation: menuIn 0.5s forwards;
}

.menuInAbout{
    margin-left: 15vw;
    animation: menuIn 0.5s forwards;
    animation-delay: 0.1s;
}

.menuInWork{
    margin-left: 15vw;
    animation: menuIn 0.5s forwards;
    animation-delay: 0.2s;
}

.menuInServices{
    margin-left: 15vw;
    animation: menuIn 0.5s forwards;
    animation-delay: 0.3s;
}

.menuInInspiration{
    margin-left: 15vw;
    animation: menuIn 0.5s forwards;
    animation-delay: 0.4s;
}

.menuInStories{
    margin-left: 15vw;
    animation: menuIn 0.5s forwards;
    animation-delay: 0.5s;
}

.menuInContactUs{
    margin-left: 15vw;
    animation: menuIn 0.5s forwards;
    animation-delay: 0.6s;
}

@keyframes menuIn{
    from{
        margin-left: 15vw;
    }
    to{ 
        margin-left: -2vw;
    }
}

.menuOutHome{
    animation: menuOut 0.5s forwards;
}

.menuOutAbout{
    margin-left: -2vw;
    animation: menuOut 0.5s forwards;
    animation-delay: 0.1s;
}

.menuOutWork{
    margin-left: -2vw;
    animation: menuOut 0.5s forwards;
    animation-delay: 0.2s;
}

.menuOutServices{
    margin-left: -2vw;
    animation: menuOut 0.5s forwards;
    animation-delay: 0.3s;
}

.menuOutInspiration{
    margin-left: -2vw;
    animation: menuOut 0.5s forwards;
    animation-delay: 0.4s;
}

.menuOutStories{
    margin-left: -2vw;
    animation: menuOut 0.5s forwards;
    animation-delay: 0.5s;
}

.menuOutContactUs{
    margin-left: -2vw;
    animation: menuOut 0.5s ;
    animation-delay: 0.6s;
}
@keyframes menuOut{
    from{
        margin-left: -2vw;
    }
    to{
        margin-left: 15vw;
    }
}


.hide{
    display: none;
}

.shift{
    top: calc((100vh - 100vw * 969/1920)/2);
}

/*//////////////////////////////////////////////////////////////////////////////////////////////////////////////////////*/

@media only screen and (max-width: 1279px) and (max-aspect-ratio: 1/1),(max-device-width: 1279px){

    .burgerBar{
        border-radius: 5px;
        border-top: 2px solid white;
        margin-bottom: 5px;
        width: 20px;
    }

    .lettersHamburger{
        top: 5px;
    }

    .logo{
        height: 24px;
        width: 50px;
        padding-top: 0.5vw;
    }
    
    
    .menu{
        left: 0;
        width: 100vw;
        opacity: 1;
        height: 0;
        top: 0vh;
        background-color: rgba(0,0,0,0.9);
        overflow: hidden;
    }

    .shift{
        top: -3vh;
        width:  calc(100vh * 414/896);
        margin-left: calc((100vw - 100vh * 414/896)/2);
    }

    .makeOpaque{
        animation: dropDown 0.5s forwards;
    }

    @keyframes dropDown {
        from{
            height: 0;
        }
        to{
            height: 85vh;
        }
    }

    .makeTransparent{
        animation: slideUp 0.5s forwards;
    }

    @keyframes slideUp {
        from{
            height: 85vh;
        }
        to{
            height: 0;
        }
    }

    .transparent{
        position: absolute;
        top: 0;
        right: calc((100vw - 100vh * 414/896)/2);
        background-color: #00000000;
    }
    

    .menuItem{
        position: relative;
        width: 100%;
        right: 5vw;
        padding-top: 3vh;
        font-size: 10vw;

        box-sizing: border-box;
        -moz-box-sizing: border-box; /* Firefox */
        -webkit-box-sizing: border-box; /* Safari, Chrome */
    }

    .menuInHome{
        margin-left: 0;
        
        animation: none;
    }
    
    .menuInAbout{
        margin-left: 0;
        
        animation: none;
    }
    
    .menuInWork{
        margin-left: 0;
        animation: none;
        
    }
    
    .menuInServices{
        margin-left: 0;
        animation: none;
        
    }
    
    .menuInInspiration{
        margin-left: 0;
        animation: none;
        
    }
    
    .menuInStories{
        margin-left: 0;
        animation: none;
        
    }
    
    .menuInContactUs{
        margin-left: 0;
        animation: none;
        
    }


    .menuOutHome{
        margin-left: 0;
        animation: none;
        
    }
    
    .menuOutAbout{
        margin-left: 0;
        animation: none;
        
    }
    
    .menuOutWork{
        margin-left: 0;
        animation: none;
        
    }
    
    .menuOutServices{
        margin-left: 0;
        animation: none;
        
    }
    
    .menuOutInspiration{
        margin-left: 0;
        animation: none;
        
    }
    
    .menuOutStories{
        margin-left: 0;
        animation: none;
        
    }
    
    .menuOutContactUs{
        margin-left: 0;
        animation: none;
    }
}

@media only screen and (min-width: 360px) and (max-aspect-ratio: 1/1) and (min-height: 900px) {

    .menuItem{
        font-size: 70px;
    }

}

@media only screen and (max-width: 1279px) and (min-aspect-ratio: 10001/10000) and (max-device-width: 1279px){

    
    .hamburgerIcon{
        top: 2px;
    }
    
    .lettersHamburger{
        top: calc((100vh - 100vw * 969/1920)/2);
        right: -35vw;
    }

    

    .burgerBar{
        border-radius: 5px;
        border-top: 1px solid white;
        margin-bottom: 5px;
        width: 20px;
    }

    .menuItem{
        font-size: 3vw;
    }

    .shift{
        top: calc((100vh - 100vw * 969/1920)/2);
        width:  100vw;
        margin-left: 0;
    }

    .logo{
        height: 5vh;
        width: 10vh;
    }
}


