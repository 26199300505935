.container{
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    background-color: #1A1A1A;
    width: 100vw;
    height: 100vh;
    color: #ffffff;
    overflow: hidden;
}

.flexboxRow1{
    display: flex;
    flex-direction: row;
    height: 43vh;
}

.flexboxRow2{
    display: flex;
    flex-direction: row;
    height: 50vh;
}

.flexboxColumn{
    display: flex;
    flex-direction: column;
}

.box1{
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    border-right: 1px solid #ffffff;
    width: 50%;
    font-size: 5vw;
    font-weight: bold;
    line-height: 100%;
    color: #000000;
    background-color: #ffffff;
    box-sizing: border-box;
    -moz-box-sizing: border-box; /* Firefox */
    -webkit-box-sizing: border-box; /* Safari, Chrome */
    animation: box1 0.5s forwards;
}

@keyframes box1{
    from{
        height: 0;
    }
    to{
        height: 100%;
    }
}

.pageHead{
    display: block;
    position: relative;
    z-index: 0;
    text-align: left;
    padding: 2%;
    padding-left: 25vw;
    animation: pageHead 0.5s forwards;
}

@keyframes pageHead{
    from{
        margin-top: 70vh;
    }
    to{
        margin-top: 0vh;
    }
}

.box2{
    display: flex;
    flex-direction: column;
    width: 50%;
    font-weight: bold;
    padding-top: 28vh;
    padding-left: 1vw;
    padding-bottom: 2vh;
    box-sizing: border-box;
    -moz-box-sizing: border-box; /* Firefox */
    -webkit-box-sizing: border-box; /* Safari, Chrome */
}

.box2:hover{
    background-color: #27343a;
}

.box3{
    position: relative;
    z-index: 5;
    width: 50%;
    padding-top: 15vh;
    box-sizing: border-box;
    -moz-box-sizing: border-box; /* Firefox */
    -webkit-box-sizing: border-box; /* Safari, Chrome */
    background-color: #1A1A1A;
}

.box4{
    display: flex;
    flex-direction: row;
    border: 2px solid #ffffff;
    width: 50%;
}

.box2Text1{
    position: relative;
    text-align: left;
    font-size: 2vw;
    animation: box2Text 0.7s forwards;
}

@keyframes box2Text{
    from{
        right: -100vw;
    }
    to{
        right: 0;
    }
}

.box2Text2{
    position: relative;
    text-align: left;
    font-size: 3vw;
    animation: box2Text 0.5s forwards;
}

.box3Text{
    display: block;
    font-size: 4vw;
    font-weight: bold;
    animation: box3Text 0.5s forwards;
}

@keyframes box3Text{
    from{
        margin-top: 100vh;
    }
    to{
        margin-top: 0;
    }
}

.detail{
    width: 25vw;
    padding: 3vh 1vw 3vh 1vw;
    box-sizing: border-box;
    -moz-box-sizing: border-box; /* Firefox */
    -webkit-box-sizing: border-box; /* Safari, Chrome */
    color: #c4c4c4;
    font-size: 1.5vw;
    font-weight: bold;
    text-align: left;
    border-right: 2px solid #ffffff; 
}

.detailText{
    animation: detailText 0.5s forwards;
}

@keyframes detailText{
    from{
        margin-top: 100vh;
    }
    to{
        margin-top: 0;
    }
}

.directionContainer{
    height: 25vh;
    width: 25vw;
    padding: 7vh 7vw 3vh 3vw;
    box-sizing: border-box;
    -moz-box-sizing: border-box; /* Firefox */
    -webkit-box-sizing: border-box; /* Safari, Chrome */
    border-bottom: 2px solid #ffffff;
}

.direction1{
    position: relative;
    width: 20vw;
    height: 10vh;
    box-sizing: border-box;
    -moz-box-sizing: border-box; /* Firefox */
    -webkit-box-sizing: border-box; /* Safari, Chrome */
    border: 2px solid #ffffff;
    font-size: 1.5vw;
    font-weight: bold;
    animation: direction 0.7s forwards;
    cursor: pointer;
}

.direction1:hover{
    background-color: #27343a;
}

.direction2{
    display: block;
    text-decoration: none;
    color: #ffffff;
    position: relative;
    width: 20vw;
    height: 10vh;
    box-sizing: border-box;
    -moz-box-sizing: border-box; /* Firefox */
    -webkit-box-sizing: border-box; /* Safari, Chrome */
    border: 2px solid #ffffff;
    font-size: 1.5vw;
    font-weight: bold;
    animation: direction 0.5s forwards;
    cursor: pointer;
}

.direction2:hover{
    background-color: #27343a;
}

@keyframes direction{
    from{
        right: -100vw;
    }
    to{
        right: 0;
    }
}



@media only screen and (max-width: 1279px) and (max-aspect-ratio: 1/1){

    .pageHead{
        position: relative;
        text-align: left;
        padding: 0 5% 0 5%;
        /* padding-left: 25vw; */
        animation: none;
        display: flex;
        flex-direction: column;
        justify-content: space-evenly;
        flex-grow: 1;
        text-align: center;
        font-size: 10vw;
    }

    .box2{
        display: flex;
        flex-direction: column;
        width: 50%;
        font-weight: bold;
        padding: 5%;
        padding-bottom: 2vh;
        box-sizing: border-box;
        -moz-box-sizing: border-box; /* Firefox */
        -webkit-box-sizing: border-box; /* Safari, Chrome */
    }

    .box2Text1{
        position: relative;
        text-align: left;
        font-size: 4vw;
        animation: none;
    }
    
    .box2Text2{
        position: relative;
        text-align: left;
        font-size: 12vw;
        animation: none;
    }
    
    .flexboxRow2{
        display: flex;
        flex-direction: column;
        border-top: 1px solid #ffffff;
    }

    .box3{
        position: relative;
        z-index: 5;
        width: 100%;
        padding: 5%;
        box-sizing: border-box;
        -moz-box-sizing: border-box; /* Firefox */
        -webkit-box-sizing: border-box; /* Safari, Chrome */
        background-color: #1A1A1A;
    }
    
    .box3Text{
        display: block;
        font-size: 6vw;
        font-weight: bold;
        text-align: left;
        animation: none;
    }

    .box4{
        width: 100%;
        border: 0;
        padding-left: 3vw;
        box-sizing: border-box;
        -moz-box-sizing: border-box; /* Firefox */
        -webkit-box-sizing: border-box; /* Safari, Chrome */
    }

    .detail{
        width: 70vw;
        border: 0;
        font-size: 4vw;
        box-sizing: border-box;
        -moz-box-sizing: border-box; /* Firefox */
        -webkit-box-sizing: border-box; /* Safari, Chrome */
    }

    .directionContainer{
        height: 15vh;
        width: 30vw;
        padding: 3vh 3vw 3vh 3vw;
        box-sizing: border-box;
        -moz-box-sizing: border-box; /* Firefox */
        -webkit-box-sizing: border-box; /* Safari, Chrome */
        border-bottom: 0;
    }

    .direction1{
        position: relative;
        width: 100%;
        height: 100%;
        box-sizing: border-box;
        -moz-box-sizing: border-box; /* Firefox */
        -webkit-box-sizing: border-box; /* Safari, Chrome */
        border: 2px solid #ffffff;
        font-size: 3vw;
        font-weight: bold;
        text-align: left;
        padding-left: 5px;
        animation: direction 0.7s forwards;
        cursor: pointer;
    }
    .direction2{
        position: relative;
        width: 100%;
        height: 100%;
        box-sizing: border-box;
        -moz-box-sizing: border-box; /* Firefox */
        -webkit-box-sizing: border-box; /* Safari, Chrome */
        border: 2px solid #ffffff;
        font-size: 3vw;
        text-align: left;
        font-weight: bold;
        padding-left: 5px;
        animation: direction 0.5s forwards;
        cursor: pointer;
    }
}

@media only screen and (min-width: 635px) and (max-width:1099px) and (max-aspect-ratio: 1/1){
    .direction1{
        font-size: 2.5vw;
    }

    .direction2{
        font-size: 2.5vw;
    }

    .box2Text1{
        font-size: 3vw;
    }

    .box2Text2{
        font-size: 10vw;
    }
}

@media only screen and (min-width: 1100px) and (max-width: 1279px){
    .direction1{
        font-size: 2vw;
    }

    .direction2{
        font-size: 2vw;
    }
}



@media only screen and (min-width: 970px) and (max-width: 1279px) and (max-aspect-ratio: 1/1){
    .box2Text1{
        font-size: 2.67vw;
    }

    .box2Text2{
        font-size: 8vw;
    }
}

@media only screen and (min-width: 910px) and (max-width: 1279px) and (max-aspect-ratio: 1/1){
    .pageHead{
        font-size: 6vw;
    }
}

@media only screen and (min-width: 750px) and (max-width: 1049px) and (max-aspect-ratio: 1/1){
    .detail{
        font-size: 3vw;
    }

    .box2Text1{
        font-size: 4vw;
    }

    .box2Text2{
        font-size: 8.5vw;
    }
}

@media only screen and (min-width: 1050px) and (max-width: 1279px) and (max-aspect-ratio: 1/1){
    .detail{
        font-size: 3vw;
    }

    .box3{
        padding: 5%;
        padding-bottom: 2%;
    }
}

@media only screen and (min-width: 1150px) and (max-width: 1279px) and (max-aspect-ratio: 1/1){
    .detail{
        font-size: 2.6vw;
    }
}

@media only screen and (max-width: 1279px) and (min-aspect-ratio: 10001/10000){
    .pageHead{
        padding: 1%;
        padding-left: 25vw;
    }
}

@media only screen and (min-width: 1320px){
    .pageHead{
        padding: 1%;
        padding-left: 25vw;
    }
}

@media only screen and (min-width: 1530px){
    .pageHead{
        padding: 0.7%;
        padding-left: 25vw;
    }
}

@media only screen and (min-width: 1600px){
    .pageHead{
        padding: 0.4%;
    }
}

@media only screen and (min-width: 1700px){
    .pageHead{
        padding: 0.2%;
        padding-left: 25vw;
    }
}

@media only screen and (min-width: 1800px){
    .pageHead{
        padding: 0%;
        padding-left: 25vw;
    }
}

@media only screen and (min-width: 1900px){
    .box1{
        font-size: 4.5vw;
    }
}