@font-face {
    font-family: futuraBold;
    font-weight: bold;
    src: url("../../assets/Futura\ Bold.otf") format("opentype");
}


.container{
    display: flex;
    flex-direction: row;
    width: 100vw;
    height: calc( 100vw * 969/1920 );
    border: 1px solid #ffffff;
    box-sizing: border-box;
    -moz-box-sizing: border-box; /* Firefox */
    -webkit-box-sizing: border-box; /* Safari, Chrome */
    overflow: hidden;
}

.dBox{
    height: 100%;
    width: 50%;
    box-sizing: border-box;
    -moz-box-sizing: border-box; /* Firefox */
    -webkit-box-sizing: border-box; /* Safari, Chrome */
}

.flexBoxColumn{
    display: flex;
    flex-direction: column;
    height: 100%;
    width: 50%;
}

.eBox{
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    /* padding-top: 5vh; */
    padding-top: 5%;
    min-height: calc(50% -  1px);
    max-height: calc(50% -  1px);
    min-width: calc(100% - 2px);
    min-width: calc(100% - 2px);
    border-bottom: 2px solid #ffffff;
    box-sizing: border-box;
    -moz-box-sizing: border-box; /* Firefox */
    -webkit-box-sizing: border-box; /* Safari, Chrome */
}

.eBoxShadeDropped{
    background-color: #1A1A1A;
}

.sBox{
    display: flex;
    flex-direction: row;
    min-height: 100%;
    max-height: 100%;
    min-width: calc(50% - 2px);
    max-width: calc(50% - 2px);
    border-right: 2px solid #ffffff;
    box-sizing: border-box;
    -moz-box-sizing: border-box; /* Firefox */
    -webkit-box-sizing: border-box; /* Safari, Chrome */
}

.sBox:hover{
    background-color: #e46f2c;;
}

.smantle{
    display: flex;
    flex-direction: row;
    height: 50%;
    width: 100%;
}

.description{
    display: flex;
    flex-direction: column;
    margin-top: 16%;
    margin-left: 4%;
    min-height: 50%;
    max-height: 50%;
    min-width: 50%;
    max-width: 50%;
    box-sizing: border-box;
    -moz-box-sizing: border-box; /* Firefox */
    -webkit-box-sizing: border-box; /* Safari, Chrome */
}

.mantle{
    display: flex;
    flex-direction: column;
    min-height: 100%;
    max-height: 100%;
    max-width: calc(50% + 2px);
    min-width: calc(50% + 2px);
}

.mBox{
    position: relative;
    z-index: 1;
    min-height: calc(50% - 2px);
    max-height: calc(50% - 2px);
    min-width: calc(100% - 0px);
    max-width: calc(100% - 0px);
    background-color: #1A1A1A;
    border-bottom: 2px solid #ffffff;
}

.mBox:hover{
    background-color: #00b475;
}

.antle{
    display:flex;
    flex-direction: row;
    min-height: calc(50%  + 1px );
    max-height: calc(50%  + 1px );
    min-width: 100%;
    max-width: 100%;
}

.aBox{
    position: relative;
    z-index: 1;
    min-height: 100%;
    max-height: 100%;
    min-width: calc( 50% - 2px );
    max-width: calc( 50% - 2px );
    /* padding-right: 2vw; */
    padding-left: 3%;
    background-color: #1A1A1A;
    border-right: 2px solid #ffffff;
    box-sizing: border-box;
    -moz-box-sizing: border-box; /* Firefox */
    -webkit-box-sizing: border-box; /* Safari, Chrome */
}

.aBox:hover{
    background-color: #9f67bb;
}

.ntle{
    display: flex;
    flex-direction: column;
    min-width: calc(50% + 2px);
    max-width: calc(50% + 2px);
    min-height: 100%;
    max-height: 100%;
}

.nBox{
    position: relative;
    min-height: calc(50% - 2px);
    max-height: calc(50% - 2px);
    min-width: 100%;
    max-width: 100%;
    /* padding-right: 2vw; */
    padding-right: 2%;
    border-bottom: 2px solid #ffffff;
}

.nBox:hover{
    background-color: #bcb832;;
}

.tle{
    position: relative;
    display: flex;
    flex-direction: row;
    height: calc(50% - 0px);
    width: 100%;
    background-color: #1A1A1A;
    box-sizing: border-box;
    -moz-box-sizing: border-box; /* Firefox */
    -webkit-box-sizing: border-box; /* Safari, Chrome */
}

.tBox{
    position: relative;
    min-height: 100%;
    max-height: 100%;
    min-width: calc(33.33% - 2px);
    max-width: calc(33.33% - 2px);
    background-color: #1A1A1A;
    border-right: 2px solid #ffffff;
}

.tBox:hover{
    /* background-color: #1A1A1A; */
    background-color: #802a27;
}

.lBox{
    position: relative;
    min-height: 100%;
    max-height: 100%;
    min-width: calc(33.33% - 0px);
    max-width: calc(33.33% - 0px);
    background-color: #1A1A1A;
    border-right: 2px solid #ffffff;
}


.lBox:hover{
    /* background-color: #1A1A1A; */
    background-color: #802a27;
}

.eSmallBox{
    position: relative;
    min-height: 100%;
    max-height: 100%;
    min-width: calc(33.33% - 2px);
    max-width: calc(33.33% - 2px);
    background-color: #1A1A1A;
}

.eSmallBox:hover{
    /* background-color: #1A1A1A; */
    background-color: #802a27;
}

.lettersTLE{
    position: relative;
    top: -1vh;
    display: block;
    color: #ffffff;
    font-family: futuraBold, sans-serif;
    font-size: 5vw;
    font-weight: 1000;
    z-index: 1;
    box-sizing: border-box;
    -moz-box-sizing: border-box; /* Firefox */
    -webkit-box-sizing: border-box; /* Safari, Chrome */
}


.LtoE1{
    animation: LtoE1 1s forwards;
}

@keyframes LtoE1{
    from{
        left:0;
    }
    to{
        /* left: 4vw; */
        left: 100%;
    }
}

.EtoL1{
    animation: EtoL1 1s forwards;
}

@keyframes EtoL1{
    from{
        left:0;
    }
    to{
        left: -100%;
    }
}

.LtoT{
    animation: LtoT 1s forwards;
}

@keyframes LtoT{
    from{
        left:100%;
    }
    to{
        left: -100%;
    }
}

.TtoL{
    animation: TtoL 1s forwards;
}

@keyframes TtoL{
    from{
        left:0;
    }
    to{
        left: 200%;
    }
}


.LtoE2{
    animation: LtoE2 1s forwards;
}

@keyframes LtoE2{
    from{
        left:-100%;
    }
    to{
        left: 0;
    }
}

.EtoL2{
    animation: EtoL2 1s forwards;
}

@keyframes EtoL2{
    from{
        left: -100%;
    }
    to{
        left: -200%;
    }
}

.TtoE{
    animation: TtoE 1s forwards;
}

@keyframes TtoE{
    from{
        left: 200%;
    }
    to{
        left: 0;
    }
}

.EtoT{
    animation: EtoT 1s forwards;
}

@keyframes EtoT{
    from{
        left: -200%;
    }
    to{
        left: 0;
    }
}

.tilt{
    animation: tilt 0.5s forwards;
    animation-delay: 0.25s;
}

@keyframes tilt{
    to{
        transform: rotate(-20deg);
    }
}

.unTilt{
    animation: unTilt 0.5s forwards;
}

@keyframes unTilt{
    from{
        transform: rotate(-20deg);
    }
    to{
        transform: rotate(-360deg);
    }
}

.heads{
    display: flex;
    flex-direction: column;
    min-width: 100%;
    max-width: 100%;
}

.headerBig{
    font-size: 6vw;
    /* font-size: 700%; */
    color: #ffffff;
    font-weight: bold;
    line-height: 90%;
    text-align: left;
}

.headerSmall{
    font-size: 2.2vw;
    /* font-size: 249.33%; */
    color: #ffffff;
    font-weight: bold;
    text-align: left;
}

.descriptionData{
    font-size: 0.8vw;
    /* font-size: 226.67%; */
    /* padding-top: 2vh; */
    padding-top: 5%;
    color: #ffffff;
    text-align: left;
}

.animateHead{
    animation: animateHead 2s forwards;
}

@keyframes animateHead{
    to{
        margin-left: -8.5vw;
    }
}

.unAnimateHead{
    animation: unAnimateHead 2s forwards;
}

@keyframes unAnimateHead{
    from{
        margin-left: -8.5vw;
    }
    to{
        margin-left: 0;
    }
}

.animateData{
    animation: animateData 2s forwards;
}

@keyframes animateData{
    from{
        opacity: 1;
    }
    to{
        opacity: 0;
    }
}

.unAnimateData{
    animation: unAnimateData 1s forwards;
}

@keyframes unAnimateData{
    from{
        opacity: 0;
    }
    to{
        opacity: 1;
    }
}

.fadeOut{
    position: fixed;
    z-index: 999;
    border:0;
    pointer-events: none;
    animation: fadeOut 0.5s forwards;
}

@keyframes fadeOut{
    from{
        opacity: 1;
    }
    to{
        opacity: 0;
    }
}

.dBoxOut{
    animation: dBoxOut 1s forwards;
}

@keyframes dBoxOut{
    from{
        transform: translateX(0);
    }
    to{
        transform: translateX(-50vw);
    }
}
  

.eBoxOut{
    border:0;
    animation: eBoxOut 1s forwards;
}

@keyframes eBoxOut{
    from{
        transform: translateY(0);
    }
    to{
        transform: translateY(-50vh);
    }
}

.sBoxOut{
    border:0;
    animation: sBoxOut 1s forwards;
}

@keyframes sBoxOut{
    from{
        transform: translateY(0);
    }
    to{
        transform: translateY(50vh);
    }
}

.mBoxOut{
    border:0;
    animation: mBoxOut 1s forwards;
}

@keyframes mBoxOut{
    from{
        transform: translateX(0);
    }
    to{
        transform: translateX(50vw);
    }
}

.aBoxOut{
    border:0;
    animation: aBoxOut 1s forwards;
}

@keyframes aBoxOut{
    from{
        transform: translateY(0);
    }
    to{
        transform: translateY(50vh);
    }
}

.nBoxOut{
    border:0;
    animation: nBoxOut 1s forwards;
}

@keyframes nBoxOut{
    from{
        transform: translateX(0);
    }
    to{
        transform: translateX(50vw);
    }
}

.tleBoxOut{
    border:0;
    animation: tleBoxOut 1s forwards;
}

@keyframes tleBoxOut{
    from{
        transform: translateX(0);
    }
    to{
        transform: translateX(50vw);
    }
}

.dynamicPadding{
    min-width: 100vw;
    min-height: calc((100vh - 100vw * 969/1920)/2);
    color: #000000;
}



.fadeIn{
    animation: fadeIn 0.5s ;
}

@keyframes fadeIn{
    from{
        opacity: 0;
    }
    to{
        opacity: 1;
    }
}

.dBoxIn{
    animation: dBoxIn 1s ;
}

@keyframes dBoxIn{
    from{
        border-color: #00000000;
        transform: translateX(-50vw);
    }
    to{
        border-color: #ffffff;
        transform: translateX(0);
    }
}
  

.eBoxIn{
    animation: eBoxIn 1s ;
}

@keyframes eBoxIn{
    from{
        border-color: #00000000;
        transform: translateY(-50vh);
    }
    to{
        border-color: #ffffff;
        transform: translateY(0);
    }
}

.sBoxIn{
    animation: sBoxIn 1s ;
}

@keyframes sBoxIn{
    from{
        border-color: #00000000;
        transform: translateY(50vh);
    }
    to{
        border-color: #ffffff;
        transform: translateY(0);
    }
}

.mBoxIn{
    animation: mBoxIn 1s ;
}

@keyframes mBoxIn{
    from{
        border-color: #00000000;
        transform: translateX(50vw);
    }
    to{
        border-color: #ffffff;
        transform: translateX(0);
    }
}

.aBoxIn{ 
    animation: aBoxIn 1s ;
}

@keyframes aBoxIn{
    from{
        border-color: #00000000;
        transform: translateY(50vh);
    }
    to{
        border-color: #ffffff;
        transform: translateY(0);
    }
}

.nBoxIn{
    animation: nBoxIn 1s ;
}

@keyframes nBoxIn{
    from{
        border-color: #00000000;;
        transform: translateX(50vw);
    }
    to{
        border-color: #ffffff;
        transform: translateX(0);
    }
}

.tleBoxIn{
    animation: tleBoxIn 1s ;
}

@keyframes tleBoxIn{
    from{
        border-color: #00000000;
        transform: translateX(50vw);
    }
    to{
        border-color: #ffffff;
        transform: translateX(0);
    }
}

.containerPortrait{
    display: none;
}

/* @media (max-aspect-ratio:  1/1) {
    .container{
        display: none;
    }
  } */

  /*//////////////////////////////////////////////////////////////////////////////////////////////////////////////////////*/

@media only screen and ( max-width: 1279px ) and (max-aspect-ratio: 1/1){
    
    .flexBoxRow{
        display: flex;
        flex-direction: row;
    }
    
    .dynamicPadding{
        display: none;
    }

    .dynamicPortraitPadding{
        min-height: 100vh;
        min-width: calc((100vw - 100vh * 414/896)/2);
        color: #000000;
    }

    .container{
        display: none;
    }

    .containerPortrait{
        display: flex;
        flex-direction: column;
        width: calc(100vh * 414/896);
        height: 100vh;
        border: 1px solid #ffffff;
        box-sizing: border-box;
        -moz-box-sizing: border-box; /* Firefox */
        -webkit-box-sizing: border-box; /* Safari, Chrome */
        overflow: hidden;
    }

    .dBox{
        height: 50%;
        width: 100%;
        box-sizing: border-box;
        -moz-box-sizing: border-box; /* Firefox */
        -webkit-box-sizing: border-box; /* Safari, Chrome */
    }

    .dBoxIn{
        animation:none;
    }
    
    .dBoxOut{
        animation:none;
    }

    .flexBoxPortraitRow{
        display: flex;
        flex-direction: row;
        height: 25%;
        width: 100%;
    }

    .eBoxPortrait{
        height: 100%;
        width: 50%;
        border-right: 1px solid #ffffff;
        border-bottom: 1px solid #ffffff;
    }

    .sBoxPortrait{
        height: 100%;
        width: 50%;
        border-bottom: 1px solid #ffffff;
    }

    .antlePortrait{
        display: flex;
        flex-direction: column;
        height: 100%;
        width: 50%;
        border-bottom: 1px solid #ffffff;
    }

    .anPortrait{
        display: flex;
        flex-direction: row;
        height: 50%;
        width: 100%;
        border-bottom: 1px solid #ffffff;
    }

    .lettersTLE{
        font-size: 10vh;
    }
    
}

 