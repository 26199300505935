.container{
    position: relative;
    background-color: #303C44;
    height: calc(70vw * 0.5);
    /* height: 70vh; */
    max-width: 32vw;
    margin: 0.1vw;
    border: 2px solid #ffffff;
    box-sizing: border-box;
    -moz-box-sizing: border-box; /* Firefox */
    -webkit-box-sizing: border-box; /* Safari, Chrome */
    overflow: hidden;
}

.raise{
    position: relative;
    top: calc(-4vw * 0.5);
}

.name{
    position: relative;
    z-index: 1;
    margin-top: calc(5vw * 0.5);
    color: #ffffff;
    font-size: 2.5vw;
    font-weight: bold;
    pointer-events: none;
}

.designation{
    position: relative;
    z-index: 1;
    color: #ffffff;
    font-size: 1.5vw;
    pointer-events: none;
}

.description{
    color: #ffffff;
    font-size: 1vw;
    text-align: left;
    padding: calc(2vw * 0.5) 1vw calc(2vw * 0.5) 1vw;
    opacity: 0;
}

.photo{
    position: absolute;
    z-index: 1;
    top: calc(20vw * 0.5);
    width:  100%;
    border-top: 2px solid #ffffff;
    height: calc(60vw * 0.5);
    pointer-events: none;
}

.image{
    width: 100%;
    height: 100%;
}

.nameAnimate{
    animation: nameAnimate 0.5s forwards;
}

@keyframes nameAnimate{
    from{
        margin-top: calc(5vw * 0.5);
    }
    to{
        margin-top: calc(2vw * 0.5);
    }
}

.designationAnimate{
    animation: designationAnimate 0.5s forwards;
}

@keyframes designationAnimate{
    from{
        color: #ffffff;
    }
    to{
        color: #008000;
    }
}

.descriptionAnimate{
    animation: descriptionAnimate 0.5s forwards;
}

@keyframes descriptionAnimate{
    from{
        opacity: 0;
    }
    to{
        opacity: 1;
    }
}

.photoAnimate{
    animation: photoAnimate 0.5s forwards;
}

@keyframes photoAnimate{
    from{
        top: calc(20vw * 0.5);
    }

    to{
        /* top: 50vh; */
        top: calc(40vw * 0.5);
    }
}


.nameUnanimate{
    animation: nameUnanimate 0.5s forwards;
}

@keyframes nameUnanimate{ 
    from{
        margin-top: calc(2vw * 0.5);
    }
    to{
        margin-top: calc(5vw * 0.5);
    }
}

.designationUnanimate{
    animation: designationUnanimate 0.5s forwards;
}

@keyframes designationUnanimate{
    from{
        color: #008000;
    }
    to{
        color: #ffffff;
    }
}

.descriptionUnanimate{
    animation: descriptionUnanimate 0.5s forwards;
}

@keyframes descriptionUnanimate{
    from{
        opacity: 1;
    }
    to{
        opacity: 0;
    }
}


.photoUnanimate{
    animation: photoUnanimate 0.5s forwards;
}

@keyframes photoUnanimate{
    from{
        /* top: 50vh; */
        top: calc(40vw * 0.5);
    }

    to{
        top: calc(20vw * 0.5);
    }
}

.containerPortrait{
    display: none;
}

@media only screen and (max-aspect-ratio: 1/1){
    
    .containerPortrait{
        display: block;
        position: relative;
        background-color: #303C44;
        height: 70vh;
        width: 93vw;
        margin: 0.1vw;
        margin-bottom: 8vh;
        border: 2px solid #ffffff;
        box-sizing: border-box;
        -moz-box-sizing: border-box; /* Firefox */
        -webkit-box-sizing: border-box; /* Safari, Chrome */
        overflow: hidden;
    }

    .container{
        display: none;
    }

    .raise{
        top: 0;
    }

    .name{
        position: relative;
        z-index: 1;
        margin-top: 5vh;
        color: #ffffff;
        font-size: 6vw;
        font-weight: bold;
        pointer-events: none;
    }

    .designation{
        position: relative;
        z-index: 1;
        color: #ffffff;
        font-size: 4vw;
        pointer-events: none;
    }

    .description{
        color: #ffffff;
        font-size: 5vw;
        text-align: left;
        padding: 2vh 1vw 2vh 1vw;
        opacity: 0;
    }

    .photo{
        height: 50vh;
        top: 20vh;
    }

    @keyframes photoAnimate{
        from{
            top: 20vh;
        }
    
        to{
            top: 50vh;
        }
    }

    @keyframes photoUnanimate{
        from{
            top: 50vh;
        }
    
        to{
            top: 20vh;
        }
    }
}


@media only screen and (min-width: 750px) and (max-aspect-ratio: 1/1){

    .description{
        font-size: 4vw;
    }
}