.container{
    display: flex;
    flex-direction: column;
    min-height: 100vh;
    width: 100vw; 
    padding-top: 10vh;
    box-sizing: border-box;
    -moz-box-sizing: border-box; /* Firefox */
    -webkit-box-sizing: border-box; /* Safari, Chrome */
}

.head{
    text-align: left;
    font-size: 4vw;
    font-weight: bold;
    color: #ffffff;
    background-color: #1a1a1a;
    position: fixed;
    width: 100vw;
    height: 17vh;
    z-index: 10;
    top: 8vh;
    padding-left: 5vw;
    pointer-events: none;
}

.main{
    display: flex;
    flex-direction: row;
    background-color: #1a1a1a;
    position: absolute;
    top: 25vh;
    background-color: #303C44;
    width: 100vw;
    box-sizing: border-box;
    -moz-box-sizing: border-box; /* Firefox */
    -webkit-box-sizing: border-box; /* Safari, Chrome */
}

.up{
    width: 20vw;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
}

.card{
    width: 100%;
    height: calc(60vw * 0.5);
}

.cardBlank{
    width: 100%;
    height: calc(30vw * 0.5);
}

.image{
    width: 100%;
    height: 100%;
}

.hide{
    display: block;
}

.show{
    display: none;
}

@media only screen and (max-width: 1279px) and (max-aspect-ratio: 1/1){

    .head{
        text-align: center;
        font-size: 8vw;
    }

    .hide{
        display: none;
    }

    .show{
        display: block;
    }

    .up{
        width: 33.33vw;
    }
}