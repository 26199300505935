.container{
    display: flex;
    flex-direction: column;
    min-height: 100vh;
    width: 100vw; 
    padding-top: 10vh;
    box-sizing: border-box;
    -moz-box-sizing: border-box; /* Firefox */
    -webkit-box-sizing: border-box; /* Safari, Chrome */
}


.head{
    text-align: left;
    font-size: 4vw;
    font-weight: bold;
    color: #ffffff;
    background-color: #1a1a1a;
    position: fixed;
    width: 100vw;
    height: 17vh;
    z-index: 10;
    top: 8vh;
    padding-left: 5vw;
    pointer-events: none;
}

.main{
    display: flex;
    flex-direction: column;
    position: absolute;
    top: 25vh;
    background-color: #303C44;
    width: 100vw;
    box-sizing: border-box;
    -moz-box-sizing: border-box; /* Firefox */
    -webkit-box-sizing: border-box; /* Safari, Chrome */
}

.categoryBox{
    position: relative;
    display: flex;
    flex-direction: row;
    width: 100vw;
    height: calc(75vh/3);
    padding: 1vh 0 1vh 1vw;
    border-top: 2px solid #ffffff;
    box-sizing: border-box;
    -moz-box-sizing: border-box; /* Firefox */
    -webkit-box-sizing: border-box; /* Safari, Chrome */
}

.categoryName{
    display: block;
    color: #ffffff;
    text-align: center;
    font-size: 2vw;
    font-weight: bold;
    text-align: left;
    width: 30vw;
    padding: 0 3vw;
    padding-top: 7vh;
    box-sizing: border-box;
    -moz-box-sizing: border-box; /* Firefox */
    -webkit-box-sizing: border-box; /* Safari, Chrome */
}

.id:hover{
    background-color: #d78145;
}

.sd:hover{
    background-color: #7d92ba;
}

.ui:hover{
    background-color: #52b987;
}

.gd:hover{
    background-color: #a176bF;
}

.branding:hover{
    background-color: #c4c053;
}

.pd:hover{
    background-color: #803730;
}

.fv:hover{
    background-color: #3b5362;
}

.dm:hover{
    background-color: #5aa1a7;
}

.imageBox{
    position: relative;
    height: 100%;
    width: 69%;
    overflow-y: hidden;
    overflow-x: scroll;
    -ms-overflow-style: none;  /* Internet Explorer 10+ */
    scrollbar-width: none;  /* Firefox */
}

.imageBox::-webkit-scrollbar { 
    display: none;  /* Safari and Chrome */
}

.imageRow{
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    position: relative;
    left: 10vw;
    height: 100%;
    width: fit-content;
    /* background-color: pink; */
}

.imageHolder{
    width: 13vw;
    height: 100%;
    margin-right: 1vw;
    box-sizing: border-box;
    -moz-box-sizing: border-box; /* Firefox */
    -webkit-box-sizing: border-box; /* Safari, Chrome */
}

.image{
    width: 100%;
    height: 100%;
}

.shade{
    position: fixed;
    display: block;
    top:0;
    right: 0;
    height: 200vh;
    width: 1px;
    z-index: 2;
    box-shadow: -0.1vw 0 4vw 1vw rgba(0, 0, 0, 1);
    pointer-events: none;
}


.arrow{
    height: 15px;
    width: 15px;
    position: absolute;
    z-index: 5;
    left: 96vw;
    top: 45%;
    margin-bottom: 20vh;
    border-bottom: 3px solid #ffffff;
    border-right: 3px solid #ffffff;
    transform: rotate(-40deg);
    /* cursor: pointer; */
    pointer-events: none;
}

.categoryNamePortrait{
    display: none;
}

@media only screen and (max-width: 1279px) and (max-aspect-ratio: 1/1){

    .container{
        padding-top: 5vh;
    }

    .main{
        top: 20vh;
    }
    
    .head{
        height: 12vh;
        text-align: center;
        font-size: 15vw;
    }

    .categoryBox{
        display: flex;
        flex-direction: row;
        width: 100vw;
        height: calc(80vh/5);
        padding: 1vh 0 1vh 1vw;
        border-top: 2px solid #ffffff;
        box-sizing: border-box;
        -moz-box-sizing: border-box; 
        -webkit-box-sizing: border-box; 
    }

    .imageBox{
        height: 100%;
        width: 100%;
    }

    .categoryName{
        display: none;
    }

    .categoryNamePortrait{
        display: block;
        color: #ffffff;
        text-align: center;
        font-size: 8vw;
        font-weight: bold;
        text-align: left;
        height: 100%;
        width: 75vw;
        padding: 0 3vw;
        padding-top: 5vh;
        box-sizing: border-box;
        -moz-box-sizing: border-box; /* Firefox */
        -webkit-box-sizing: border-box; /* Safari, Chrome */
    }

    .imageHolder{
        height: 25vw;
        width: 25vw;
    }

    .arrow{
        left: 90vw;
    }

}

@media only screen and (min-width: 640px) and (max-aspect-ratio: 1/1){
    .head{
        font-size: 10vw;
    }

    .categoryNamePortrait{
        font-size: 6vw;
    }
}

@media only screen and (min-width: 980px) and (max-aspect-ratio: 1/1)  {
    .head{
        font-size: 7vw;
    }

    .categoryNamePortrait{
        font-size: 4vw;
    }
}
