.container{
    background-color: #1A1A1A;
    overflow: auto;
}

.hamburgerIcon{
    position: fixed;
    display: flex;
    flex-direction: column;
    z-index: 10;
    top: 15px;
    right: 3.65vw;
    cursor: pointer;
}

.logo{
    position: fixed;
    z-index: 10;
    top: 15px;
    left: 3.65vw;
}

.burgerBar{
    border-radius: 5px;
    border-top: 5px solid white;
    margin-bottom: 5px;
    width: 40px;
}

.rotate90{
    animation: rotate90 0.5s forwards;
}

@keyframes rotate90{
    from{
        transform: rotate(0deg);
    }
    to{
        transform: rotate(-90deg);
    }
}

.rotateReverse90{
    animation: rotateReverse90 0.5s forwards;
}

@keyframes rotateReverse90{
    from{
        transform: rotate(-90deg);
    }
    to{
        transform: rotate(0deg);
    }
}

.lettersAndMenu{
    position: relative;
    display: flex;
    padding: 0;
    flex-direction: row;
    max-width: 100vw;
    overflow-x : hidden;
}

.menu{
    position: absolute;
    width: 23vw;
    margin-top: 7vh;
    height: fit-content;
    left: 90vw;
    list-style-type: none;
    z-index: 10;
}

.menuItem{
    color: #c4c5c7;
    text-align: right;
    margin-left: 15vw;
    width: 10vw;
    font-size: 1.5vw;
    padding: 1vh 0 1vh 0;
    cursor: pointer;
}

.menuItem:hover{
    color: #ffffff;
}

.menuInHome{
    animation: menuIn 0.5s forwards;
}

.menuInAbout{
    animation: menuIn 0.5s forwards;
    animation-delay: 0.1s;
}

.menuInWork{
    animation: menuIn 0.5s forwards;
    animation-delay: 0.2s;
}

.menuInServices{
    animation: menuIn 0.5s forwards;
    animation-delay: 0.3s;
}

.menuInInspiration{
    animation: menuIn 0.5s forwards;
    animation-delay: 0.4s;
}

.menuInStories{
    animation: menuIn 0.5s forwards;
    animation-delay: 0.5s;
}

.menuInContactUs{
    animation: menuIn 0.5s forwards;
    animation-delay: 0.6s;
}

@keyframes menuIn{
    from{
        margin-left: 15vw;
    }
    to{
        margin-left: -5vw;
    }
}

.menuOutHome{
    animation: menuOut 0.5s forwards;
}

.menuOutAbout{
    margin-left: -5vw;
    animation: menuOut 0.5s forwards;
    animation-delay: 0.1s;
}

.menuOutWork{
    margin-left: -5vw;
    animation: menuOut 0.5s forwards;
    animation-delay: 0.2s;
}

.menuOutServices{
    margin-left: -5vw;
    animation: menuOut 0.5s forwards;
    animation-delay: 0.3s;
}

.menuOutInspiration{
    margin-left: -5vw;
    animation: menuOut 0.5s forwards;
    animation-delay: 0.4s;
}

.menuOutStories{
    margin-left: -5vw;
    animation: menuOut 0.5s forwards;
    animation-delay: 0.5s;
}

.menuOutContactUs{
    margin-left: -5vw;
    animation: menuOut 0.5s forwards;
    animation-delay: 0.6s;
}
@keyframes menuOut{
    from{
        margin-left: -5vw;
    }
    to{
        margin-left: 15vw;
    }
}


.componentContainer{
    height: fit-content;
}
